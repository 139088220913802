import { getAppData } from '../../lib/api/common'
import { getLoginPage } from '../../lib/api/singles'

import Layout from '../../components/layout/Layout'
import SeoMaticHead from '../../components/common/SeoMaticHead'
import PageHeaderBasic from '../../components/content/page/PageHeaderBasic'
import LoginsList from '../../components/content/LoginsList'
import { revalidateSeconds } from "../../lib/helpers/revalidateSeconds";

export type LoginPageProps = {
	page: any
	updatesPage: any
	events: Array<any>
	app: any
}


export async function getStaticProps(context: any) {

	const app: any = await getAppData();
	const data: any = await getLoginPage(
		context.preview ? context.previewData?.token : undefined
	);

	return {
		props: {
			app: app,
			page: data?.page,
		},
		revalidate: revalidateSeconds()
	};
}



export default function LoginPage(props: LoginPageProps) {

	const { page, app } = props

	// console.log('Login page props: ', props)

	return (
		<>
			<SeoMaticHead seo={page.seo} />
			<Layout
				appData={app}
				theme={`dark`}
			>
				<>

					<PageHeaderBasic
						block={page.pageHeader?.length > 0 ? page.pageHeader[0] : null}
						forceNoBackground={true}
					/>

					<LoginsList blocks={page.logins} />

				</>
			</Layout>
		</>
	)
}
