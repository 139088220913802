import { useState } from 'react'

import { ChevronDown } from '../svgs/Icons'
import LinkByType from '../common/LinkByType'

interface LoginsListProps {
	blocks: any
}

const LoginGroup = (props: any) => {

	const { loginGroup } = props
	const [isOpen, setIsOpen] = useState(loginGroup.isOpen)

	return (
		<li className={`logins-list__group logins-list__group--is-${isOpen ? 'open' : 'closed'}`}>
			<div className='logins-list__group-inner'>
				<button className='logins-list__group-heading' onClick={() => setIsOpen(!isOpen)}>
					<div className='logins-list__group-heading-text'>
						<span>{loginGroup.heading}</span>
					</div>
					<div className='logins-list__group-question-icon'>
						<div className='logins-list__group-question-icon-inner'>
							<ChevronDown />
						</div>
					</div>
				</button>
				<div className={`logins-list__group-content logins-list__group-content--is-${isOpen ? 'open' : 'closed'}`}>
					{loginGroup.children.map((child: any) => {
						return (
							<div key={child.id} className={`logins-list__group-content-item`}>
								<div className={`logins-list__group-content-text-container`}>
									<span className='logins-list__group-content-heading'>{child.heading}</span>
									<span className={`logins-list__group-content-text`} dangerouslySetInnerHTML={{__html: child.shortDescription}} />
								</div>
								<div className={`logins-list__group-content-link-container`}>
									<LinkByType link={child.linkTo} linkClass={`button button--on-dark`} />
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</li>
	)

}



const LoginsList = (props: LoginsListProps) => {

	const { blocks } = props

	if ( !blocks || blocks.length === 0 ) {
		return null
	}

	return (
		<section className='logins-list'>
			<div className='logins-list__wrapper'>
				<div className='logins-list__content'>
					<ul className='logins-list__list'>
						{blocks.map((item: any) => {
							return <LoginGroup key={item.id} loginGroup={item} />
						})}
					</ul>
				</div>
			</div>
		</section>
	)

}

export default LoginsList
